<script>
import Layout from "../layouts/main";
import appConfig from "@/app.config";

import { getDueSubscriptions } from "@/api/Subscriptions";
import { getOverview } from "@/api/Stats";

import { Subscription } from "@/models/Subscription";

import Stat from "@/components/widgets/stat";
import SubscriptionsTable from "@/components/widgets/subscriptions-table";
import SalesChart from "@/components/widgets/sales-chart";
import SubscriptionsStats from "@/components/widgets/subscriptions-stats";

/**
 * Dashboard Component
 */
export default {
  page: {
    title: "Dashboard",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, Stat, SalesChart, SubscriptionsTable, SubscriptionsStats },
  data() {
    return {
      title: "Dashboard",
      ui: {
        loading: false,
      },
      statData: [
        {
          icon: "bx bx-wallet",
          title: "Saldo em conta",
          value: 0,
        },
        {
          icon: "bx bx-calendar",
          title: "MRR",
          value: 0,
        },
        {
          icon: "bx bx-store",
          title: "Total de vendas neste mês",
          value: 0,
        },
        {
          icon: "bx bx-dollar-circle",
          title: "Comissão prevista (0%)",
          value: 0,
        },
      ],
      dueSubscriptions: [],
    };
  },
  mounted() {
    let vm = this;
    this.ui.loading = true;
  
    getOverview().then(response => {
      vm.statData[0].value = response.balance;
      vm.statData[1].value = response.mrr;
      vm.statData[2].value = response.sales;
      vm.statData[3].title = `Comissão prevista (${response.commission.percent}%)`;
      vm.statData[3].value = response.commission.amount;
    });
  
    getDueSubscriptions().then(response => {
      vm.dueSubscriptions = response.data.map(item => {
        return new Subscription(item);
      });
    });
  },
};
</script>

<template>
  <Layout>
    <!-- start page title -->
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex align-items-center justify-content-between">
          <h4 class="mb-0 font-size-18">
            Dashboard
          </h4>

          <div class="page-title-right">
            <ol class="breadcrumb m-0">
              <li class="breadcrumb-item active">
                Bem-vindo ao Painel de Parceiros Huggy!
              </li>
            </ol>
          </div>
        </div>
      </div>
    </div>
    <!-- end page title -->
    <div class="row">
      <div
        v-for="stat of statData"
        :key="stat.icon"
        class="col-md-3"
      >
        <Stat
          :icon="stat.icon"
          :title="stat.title"
          :value="stat.value"
        />
      </div>
    </div>
    
    <div class="row">
      <div class="col-xl-8">
        <SalesChart />
      </div>
      <div class="col-xl-4">
        <SubscriptionsStats />
      </div>
    </div>
    <!-- end row -->

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4">
              Assinaturas vencidas
            </h4>
            <!-- Transactions table -->
            <SubscriptionsTable :subscriptions="dueSubscriptions" />
          </div>
        </div>
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>
