import { axios } from '@/plugins/axios';

export const getSubscriptions = (params) => axios.get('/subscriptions', {params: params})
  .then(res => res.data);

export const getDueSubscriptions = (params) => axios.get('/subscriptions/due', {params: params})
  .then(res => res.data);

export const getSubscription = (id) => {
  return axios
    .get(`/subscriptions/${id}`)
    .then(res => {
      if (res.data) {
        return res.data;
      }
      return {};
    });
};

export const getSubscriptionEvents = (id) => {
  return axios
    .get(`/subscriptions/${id}/events`)
    .then(res => {
      if (res.data) {
        return res.data;
      }
      return {};
    });
};

export const getSubscriptionItems = (id) => {
  return axios
    .get(`/subscriptions/${id}/items`)
    .then(res => {
      if (res.data) {
        return res.data;
      }
      return {};
    });
};

export const syncSubscription = (subscription) => {
  return axios
    .get(`/subscriptions/${subscription.id}/sync`)
    .then(res => {
      if (res.data) {
        return res.data;
      }
      return {};
    });
};

export const activateDemo = (id, subscription) => {
    return axios
        .post(`/subscriptions/${id}/demo`, subscription)
        .then(res => {
            if (res.data) {
                return res.data;
            }
            return {};
        });
};

export const orderSubscription = (id, subscription) => {
    return axios
        .post(`/subscriptions/${id}/order`, subscription)
        .then(res => {
            if (res.data) {
                return res.data;
            }
            return {};
        });
};

export const enableSubscription = (subscription) => {
  return axios
    .put(`/subscriptions/${subscription.id}/enable`, subscription)
    .then(res => {
      if (res.data) {
        return res.data;
      }
      return {};
    });
};

export const updateSubscription = (subscription) => {
  return axios
    .put(`/subscriptions/${subscription.id}`, subscription)
    .then(res => {
      if (res.data) {
        return res.data;
      }
      return {};
    });
};

export const suspendSubscription = (subscription) => {
  var obj = {
    suspended: !subscription.suspended,
  };

  return axios
    .put(`/subscriptions/${subscription.id}/suspend`, obj)
    .then(res => {
      if (res.data) {
        return res.data;
      }
      return {};
    });
};


export const editExperimentation = (subscription) => {
  return axios
    .put(`/subscriptions/${subscription.id}/experimentation`, subscription)
    .then(res => {
      if (res.data) {
        return res.data;
      }
      return {};
    });
};


export const cancelSubscription = (subscription) => {
  return axios
    .delete(`/subscriptions/${subscription.id}`)
    .then(res => {
      if (res.data) {
        return res.data;
      }
      return {};
    });
};


export const getChargeDays = (params) => {
  return axios
    .get(`/subscriptions/charge-days?date=${params.date}&cycle=${params.cycle}&method=${params.method}`)
    .then(res => {
      if (res.data) {
        return res.data;
      }
      return {};
    });
};

export const getLogs = (id) => axios.get(`/subscriptions/${id}/logs`)
  .then(res => {
    if (res.data) {
      return res.data;
    }
    return {};
  });

export const listCancellationReasons = () => axios.get('/subscription-cancellation-reasons')
  .then(res => res.data);

export const requestCancellation = (id, params) => {
  return axios
    .post(`/subscriptions/${id}/request-cancellation`, params)
    .then(res => {
      if (res.data) {
        return res.data;
      }
      return {};
    });
};
