<script>/**
 * Subscriptions component
 */

import { getSubscription } from "@/api/Subscriptions";
import { Subscription } from "@/models/Subscription";

export default {
  props: {
    subscriptions: {
      type: Array,
      default: function() {
        return [];
      },
    },
  },
  data() {
    return {
      loading: false,
      showModal: false,
      subscription: new Subscription(),
    };
  },
  computed: {
    items: function() {
      return this.subscription.items;
    },
    subtotal: function() {
      return this.subscription.items.reduce( function(total, item){
        return total + (item.price * item.quantity);
      }, 0);
    },
    discount: function() {
      if(this.subscription.coupon_id !== null){
        if(this.subscription.coupon.type === 'once'){
          return this.subscription.coupon.value < this.subtotal ? this.subscription.coupon.value : this.subtotal;
        }

        return this.subscription.coupon.value * this.subtotal / 100;
      }

      return 0;
    },
    total: function() {
      return this.subtotal - this.discount;
    },
  },
  methods: {
    details(id){
      let vm = this;
      this.loading = true;
      this.showModal = true;

      getSubscription(id).then((response) => {
        vm.subscription = new Subscription(response);
        vm.loading = false;
      });
    },
  },
};
</script>

<template>
  <div class="table-responsive mb-0">
    <table class="table table-centered table-nowrap">
      <thead class="thead-light">
        <tr>
          <th>ID</th>
          <th>ID Empresa</th>
          <th>Empresa</th>
          <th>Plano</th>
          <th>Agentes</th>
          <th>Forma de Pagamento</th>
          <th>Vencimento</th>
          <th />
          <th />
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="data in subscriptions"
          :key="data.id"
        >
          <td>{{ data.id }}</td>
          <td>{{ data.company.id }}</td>
          <td>{{ data.company.name }}</td>
          <td>{{ data.package.name }}</td>
          <td>{{ data.agent }} <small class="text-muted">({{ data.aditional_agent }} adicionais)</small></td>
          <td>{{ $t(`global.methods.${data.payment_method}`) }}</td>
          <td>{{ $d(data.period_end, 'short') }}</td>
          <td class="min">
            <!-- Button trigger modal -->
            <button
              type="button"
              class="btn btn-light btn-sm btn-rounded"
              @click="details(data.id)"
            >
              Detalhes
            </button>
          </td>
          <td class="min">
            <button
              type="button"
              class="btn btn-light btn-sm btn-rounded"
              @click="$router.push(`/subscriptions/${data.id}`)"
            >
              <i class="mdi mdi-eye" />
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <b-modal
      v-model="showModal"
      title="Assinatura"
      centered
    >
      <p class="mb-2">
        Empresa:
        <span>{{ subscription.company.name }} (#{{ subscription.company.id }})</span>
      </p>
      <p class="mb-2">
        Vencimento:
        <span>{{ $d(subscription.period_end, 'short') }}</span>
      </p>
      <p class="mb-2">
        Forma de pagamento:
        <span>{{ $t(`global.methods.${subscription.payment_method}`) }}</span>
      </p>
      <p class="mb-4">
        Ciclo de pagamento:
        <span>{{ $t(`content.plans.${subscription.cycle}`) }}</span>
      </p>
      <div class="table-responsive">
        <table class="table table-centered table-nowrap">
          <thead>
            <tr>
              <th scope="col">
                Item
              </th>
              <th
                scope="col"
                class="text-right"
              >
                Subtotal
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="item in items"
              v-show="item.quantity > 0"
              :key="item.id"
            >
              <td>
                <div>
                  <h5 class="text-truncate font-size-14">
                    {{ item.description }}
                  </h5>
                  <p class="text-muted mb-0">
                    {{ $n(item.price, 'currency', subscription.company.currency) }} x {{ item.quantity }}
                  </p>
                </div>
              </td>
              <td class="text-right">
                {{ $n(item.price * item.quantity, 'currency', subscription.company.currency) }}
              </td>
            </tr>
            <tr>
              <td>
                <h6 class="m-0 text-right">
                  Subtotal:
                </h6>
              </td>
              <td class="text-right">
                {{ $n(subtotal, 'currency', subscription.company.currency) }}
              </td>
            </tr>
            <tr>
              <td>
                <h6 class="m-0 text-right">
                  Desconto:
                </h6>
              </td>
              <td class="text-right">
                {{ $n(discount, 'currency', subscription.company.currency) }}
              </td>
            </tr>
            <tr>
              <td>
                <h6 class="m-0 text-right">
                  Total:
                </h6>
              </td>
              <td class="text-right">
                {{ $n(total, 'currency', subscription.company.currency) }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <template v-slot:modal-footer>
        <b-button
          variant="secondary"
          @click="showModal = false"
        >
          Fechar
        </b-button>
      </template>
    </b-modal>
  </div>
  <!-- end table -->
</template>
