<script>
import { getSubscriptions } from "@/api/Stats";
/**
 * Email-sent component
 */
export default {
  data() {
    return {
      total: [0, 0, 0, 0],
      series: [0, 0, 0, 0],
      chartOptions: {
        plotOptions: {
          radialBar: {
            dataLabels: {
              name: {
                fontSize: '22px',
              },
              value: {
                show: true,
                offsetY: 10,
                fontSize: '22px',
                formatter: function(val) {
                  return val + '%';
                },
              },
            },
          },
        },
        labels: ['Starter', 'Básico', 'Intermediário', 'Avançado'],
        colors: ['#556ee6', '#34c38f', '#f46a6a', '#f1b44c'],
      },
    };
  },
  created() {
    let vm = this;
    
    getSubscriptions().then(response => {
      vm.series = [
        response.free.percent,
        response.basic.percent,
        response.intermediate.percent,
        response.advanced.percent,
      ];
  
      vm.total = [
        response.free.total,
        response.basic.total,
        response.intermediate.total,
        response.advanced.total,
      ];
    });
  },
};
</script>

<template>
  <div class="card">
    <div class="card-body">
      <h4 class="card-title mb-4">
        Assinaturas
      </h4>
      <div class="row text-center">
        <div class="col-sm-3">
          <h5 class="mb-0">
            {{ total[0] }}
          </h5>
          <p
            style="color: #556ee6"
            class="mb-0"
          >
            Starter
          </p>
        </div>
        <div class="col-sm-3">
          <h5 class="mb-0">
            {{ total[1] }}
          </h5>
          <p
            style="color: #34c38f"
            class="mb-0"
          >
            Básico
          </p>
        </div>
        <div class="col-sm-3">
          <h5 class="mb-0">
            {{ total[2] }}
          </h5>
          <p
            style="color: #f46a6a"
            class="mb-0"
          >
            Intermed.
          </p>
        </div>
        <div class="col-sm-3">
          <h5 class="mb-0">
            {{ total[3] }}
          </h5>
          <p
            style="color: #f1b44c"
            class="mb-0"
          >
            Avançado
          </p>
        </div>
      </div>
      
      <apexchart
        class="apex-charts"
        height="360"
        type="radialBar"
        dir="ltr"
        :series="series"
        :options="chartOptions"
      />
    </div>
  </div>
</template>
