import { axios } from '@/plugins/axios';

export const getOverview = () => axios.get(`/stats/overview`)
  .then(res => res.data);

export const getSales = () => axios.get(`/stats/sales`)
  .then(res => res.data);

export const getSubscriptions = () => axios.get(`/stats/subscriptions`)
  .then(res => res.data);
