<script>
import { getSales } from "@/api/Stats";
/**
 * Email-sent component
 */
export default {
  data() {
    return {
      series: [
        {
          name: "Volume de vendas",
          type: 'column',
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        },
        {
          name: "Total de vendas",
          type: 'column',
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        },
        {
          name: "Cancelamentos",
          type: 'column',
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        },
      ],
      chartOptions: {
        chart: {
          stacked: false,
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: true,
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          categories: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
            "Jan",
          ],
        },
        colors: ["#34c38f","#556ee6", "#F46A6A"],
        fill: {
          opacity: 1,
        },
        yaxis: [
          {
            seriesName: 'Volume de vendas',
            axisTicks: {
              show: true,
            },
            axisBorder: {
              show: true,
              color: '#34c38f',
            },
            labels: {
              style: {
                colors: '#34c38f',
              },
            },
            title: {
              text: "Volume de vendas",
              style: {
                color: '#34c38f',
              },
            },
            tooltip: {
              enabled: true,
            },
          },
          {
            seriesName: 'Total',
            opposite: true,
            axisTicks: {
              show: true,
            },
            axisBorder: {
              show: true,
              color: '#556ee6',
            },
            labels: {
              style: {
                colors: '#556ee6',
              },
            },
            title: {
              text: "Total de vendas",
              style: {
                color: '#556ee6',
              },
            },
          },
          {
            seriesName: 'Cancelamentos',
            opposite: true,
            axisTicks: {
              show: true,
            },
            axisBorder: {
              show: true,
              color: '#F46A6A',
            },
            labels: {
              style: {
                colors: '#F46A6A',
              },
            },
            title: {
              text: "Cancelamentos",
              style: {
                color: '#F46A6A',
              },
            },
          },
        ],
        legend: {
          position: "bottom",
          horizontalAlign: 'left',
          offsetX: 40,
        },
      },
    };
  },
  created() {
    let vm = this;
  
    getSales().then(response => {
      vm.chartOptions =  {
        chart: {
          stacked: false,
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: true,
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          categories: [
            response[0].period,
            response[1].period,
            response[2].period,
            response[3].period,
            response[4].period,
            response[5].period,
            response[6].period,
            response[7].period,
            response[8].period,
            response[9].period,
            response[10].period,
            response[11].period,
            response[12].period,
          ],
        },
        colors: ["#34c38f","#556ee6", "#F46A6A"],
        fill: {
          opacity: 1,
        },
        yaxis: [
          {
            seriesName: 'Volume de vendas',
            axisTicks: {
              show: true,
            },
            axisBorder: {
              show: true,
              color: '#34c38f',
            },
            labels: {
              style: {
                colors: '#34c38f',
              },
            },
            title: {
              text: "Volume de vendas",
              style: {
                color: '#34c38f',
              },
            },
            tooltip: {
              enabled: true,
            },
          },
          {
            seriesName: 'Total',
            opposite: true,
            axisTicks: {
              show: true,
            },
            axisBorder: {
              show: true,
              color: '#556ee6',
            },
            labels: {
              style: {
                colors: '#556ee6',
              },
            },
            title: {
              text: "Total de vendas",
              style: {
                color: '#556ee6',
              },
            },
          },
          {
            seriesName: 'Cancelamentos',
            opposite: true,
            axisTicks: {
              show: true,
            },
            axisBorder: {
              show: true,
              color: '#F46A6A',
            },
            labels: {
              style: {
                colors: '#F46A6A',
              },
            },
            title: {
              text: "Cancelamentos",
              style: {
                color: '#F46A6A',
              },
            },
          },
        ],
        legend: {
          horizontalAlign: 'left',
          offsetX: 40,
        },
      };
      
      vm.series = [
        {
          name: "Volume de vendas",
          type: 'column',
          data: [
            response[0].amount,
            response[1].amount,
            response[2].amount,
            response[3].amount,
            response[4].amount,
            response[5].amount,
            response[6].amount,
            response[7].amount,
            response[8].amount,
            response[9].amount,
            response[10].amount,
            response[11].amount,
            response[12].amount,
          ],
        },
        {
          name: "Total de vendas",
          type: 'column',
          data: [
            response[0].total,
            response[1].total,
            response[2].total,
            response[3].total,
            response[4].total,
            response[5].total,
            response[6].total,
            response[7].total,
            response[8].total,
            response[9].total,
            response[10].total,
            response[11].total,
            response[12].total,
          ],
        },
        {
          name: "Cancelamentos",
          type: 'column',
          data: [
            response[0].cancellations,
            response[1].cancellations,
            response[2].cancellations,
            response[3].cancellations,
            response[4].cancellations,
            response[5].cancellations,
            response[6].cancellations,
            response[7].cancellations,
            response[8].cancellations,
            response[9].cancellations,
            response[10].cancellations,
            response[11].cancellations,
            response[12].cancellations,
          ],
        },
      ];
    });
  },
};
</script>

<template>
  <div class="card">
    <div class="card-body">
      <h4 class="card-title mb-4">
        Vendas Realizadas
      </h4>

      <apexchart
        class="apex-charts"
        type="bar"
        dir="ltr"
        height="400"
        :series="series"
        :options="chartOptions"
      />
    </div>
  </div>
</template>
